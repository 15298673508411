







import IdentityState from '@/app_code/IdentityState'
import { Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Login extends Vue {
  mounted () {
    Services.API.Users.Me().then((me) => {
      // We already have a session, so continue to the dashboard straight away
      this.$store.commit('setIdentityState', { LoggedIn: true, User: me } as IdentityState)

      this.next()
    }).catch(() => {
      this.toLogin()
    })
  }

  next () {
    const nextPath = this.$route.query?.next as string

    this.$router.push({
      path: nextPath ?? '/dashboard'
    })
  }

  toLogin () {
    this.$router.push({
      path: '/account/login',
      query: this.$route.query
    })
  }
}
